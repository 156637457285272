import React, { useEffect, useMemo, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import {
  Box,
  IconButton,
  Link,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import {
  useCouponListLazyQuery,
  CouponRequestType,
  Coupon,
  useCreateSharedCouponMutation,
} from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import moment from 'moment/moment';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { CouponStepsView } from './coupons-types';
import { copyToClipboard, formatCurrencyMXN } from '../../shared/utils';
import { IosShare as ShareIcon } from '@mui/icons-material';
import { ShareCouponModal } from './components/ShareCouponModal';
import { openDialogAlert } from '../alert/alert-actions';

export const CouponsView: React.FC = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState<Coupon | null>(null);
  const [count, setCount] = useState(0);
  const [currentStep, setCurrentStep] = useState<CouponStepsView>(
    CouponStepsView.ASSIGNED,
  );
  const [requestType, setRequestType] = useState<CouponRequestType>(
    CouponRequestType.Assigned,
  );
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [callAction, { data, loading }] = useCouponListLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (event) => {
      setCount(event.fetchCouponList?.count ?? 0);
    },
  });

  const [callSharedCoupon, { loading: loadingSharedCoupon }] =
    useCreateSharedCouponMutation({
      onCompleted: () => {
        setOpenShareModal(false);
        openDialogAlert('Cupón compartido con éxito');
      },
      onError: (error) => {
        console.error(error);
      },
    });

  useEffect(() => {
    if (user) {
      callAction({
        variables: {
          where: {
            user_id: user.id,
            requestType: requestType,
          },
          page: pagination.page - 1,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [user, callAction, pagination, requestType]);

  const handleChangeStep = (step: CouponStepsView): void => {
    setCurrentStep(step);
    setPagination({
      ...pagination,
      page: 1,
    });

    if (step === CouponStepsView.ASSIGNED) {
      setRequestType(CouponRequestType.Assigned);
      return;
    }
    if (step === CouponStepsView.AVAILABLE) {
      setRequestType(CouponRequestType.Available);
      return;
    }
    if (step === CouponStepsView.USED) {
      setRequestType(CouponRequestType.Used);
      return;
    }
  };

  const coupons = (data?.fetchCouponList?.coupons as Coupon[]) ?? [];

  const COLUMNS = useMemo(() => {
    const columns: TableColumnType<Coupon>[] = [
      {
        columnName: 'Cupon',
        columnValue:
          currentStep === CouponStepsView.USED
            ? (item) => item?.coupon ?? ''
            : null,
        columnComponent: (item) => (
          <Link
            underline="hover"
            onClick={() =>
              copyToClipboard(item?.coupon ?? '', 'Cupon copiado!')
            }
            sx={{
              color: '#000',
              fontWeight: 700,
              cursor: 'pointer',
            }}>
            {item.coupon}
          </Link>
        ),
      },
      {
        columnName: 'Descuento',
        columnValue: (item) =>
          (item?.discount_percent ?? 0) > 0
            ? item.discount_percent + '%'
            : formatCurrencyMXN(Number(item.discount_amount)),
      },
      ...(currentStep === CouponStepsView.USED
        ? [
            {
              columnName: 'Fecha de uso',
              columnValue: (item: Coupon) =>
                item.used_at
                  ? moment(parseInt(item.used_at as string)).format(
                      'DD-MM-YYYY',
                    )
                  : 'N/A',
            },
            {
              columnName: 'Plan',
              columnValue: (item: Coupon) => item.plan?.title ?? 'N/A',
            },
          ]
        : [
            {
              columnName: 'Fecha de expiración',
              columnValue: (item: Coupon) =>
                item.end_date
                  ? moment(parseInt(item.end_date as string)).format(
                      'DD-MM-YYYY',
                    )
                  : 'N/A',
            },
            {
              columnName: 'Limite de uso',
              columnValue: (item: Coupon) => item.max_redemptions ?? 'N/A',
            },
            {
              columnName: 'Planes asociados',
              columnValue: (item: Coupon) => item.plan_names ?? 'N/A',
            },
          ]),
      ...(currentStep === CouponStepsView.AVAILABLE
        ? [
            {
              columnName: 'Compartir',
              columnValue: null,
              columnComponent: (item: Coupon) =>
                item.is_for_sharing ? (
                  <IconButton
                    onClick={() => {
                      setOpenShareModal(true);
                      setCurrentCoupon(item);
                    }}>
                    <ShareIcon />
                  </IconButton>
                ) : null,
            },
          ]
        : []),
    ];

    if (currentStep === CouponStepsView.ASSIGNED) {
      columns.push({
        columnName: 'Compartir',
        columnValue: null,
        columnComponent: (item: Coupon) =>
          item.assigned_to_share ? (
            <IconButton
              onClick={() => {
                setOpenShareModal(true);
                setCurrentCoupon(item);
              }}>
              <ShareIcon />
            </IconButton>
          ) : null,
      });
    }

    return columns;
  }, [currentStep]);

  return (
    <>
      <ShareCouponModal
        coupon={currentCoupon?.coupon ?? ''}
        open={openShareModal && !!currentCoupon}
        onClose={() => {
          setCurrentCoupon(null);
          setOpenShareModal(false);
        }}
        onSubmit={(emails) => {
          console.log(emails);
          callSharedCoupon({
            variables: { emails, couponId: currentCoupon?.id as number },
          });
        }}
        loading={loadingSharedCoupon}
      />
      <CardSection
        title={
          <Typography variant="h6" fontWeight={800}>
            Cupones
          </Typography>
        }
        subheader={
          <Typography variant="subtitle1">
            Acá encontrarás información detallada de tu cupones.
          </Typography>
        }>
        <Tabs
          value={currentStep}
          onChange={(event, newValue) => handleChangeStep(newValue)}
          variant="fullWidth">
          <Tab
            label={CouponStepsView.ASSIGNED}
            value={CouponStepsView.ASSIGNED}
            sx={{
              textTransform: 'none',
              ...(currentStep !== CouponStepsView.ASSIGNED && {
                borderBottom: '0.5px solid ' + theme.palette.divider,
              }),
            }}
          />
          <Tab
            label={CouponStepsView.AVAILABLE}
            value={CouponStepsView.AVAILABLE}
            sx={{
              textTransform: 'none',
              ...(currentStep !== CouponStepsView.AVAILABLE && {
                borderBottom: '0.5px solid ' + theme.palette.divider,
              }),
            }}
          />
          <Tab
            label={CouponStepsView.USED}
            value={CouponStepsView.USED}
            sx={{
              textTransform: 'none',
              ...(currentStep !== CouponStepsView.USED && {
                borderBottom: '0.5px solid ' + theme.palette.divider,
              }),
            }}
          />
        </Tabs>

        <TableDefault
          count={count}
          items={coupons}
          columns={COLUMNS}
          page={pagination.page}
          pageSize={pagination.pageSize}
          loading={loading}
          messageLoading={'Cargando cupones...'}
          showPagination={count > 0}
          handleChangePage={(page) => {
            setPagination({
              ...pagination,
              page: page + 1,
            });
          }}
        />
        {!loading && coupons.length === 0 && (
          <Box py={'15px'}>
            <Typography textAlign="center">
              No se encontraron cupones.
            </Typography>
          </Box>
        )}
      </CardSection>
    </>
  );
};
